import * as React from 'react';
import axios from 'axios';

interface BookmarkParams {
  authenticity_token: string,
  is_bookmarked: boolean,
  bookmark_id: number,
  chapter_id: number,
};

const Bookmark = (props: BookmarkParams) => {
  const iconClass: string = 'fa fa-bookmark ';
  const bookmarkedClass: string = props.is_bookmarked ? 'bookmarked' : 'unbookmarked';
  const [bookmarkClass, setBookmarkClass] = React.useState(iconClass + bookmarkedClass);
  const [bookmarkId, setBookmarkId] = React.useState(props.bookmark_id);

  const hasBookmarked = () => bookmarkClass.split(' ').indexOf('bookmarked') !== -1;

  const bookmarkClickHandler = (e) => {
    e.preventDefault();

    setBookmarkClass(`fa fa-spinner bookmarking`);

    if (hasBookmarked()) {
      // Delete bookmark
      axios.delete(`/bookmarks/${bookmarkId}.json`, {
        params: {
          authenticity_token: props.authenticity_token,
        }
      })
        .then(_response => {
          setBookmarkClass(`${iconClass} unbookmarked`);
          setBookmarkId(0);
        })
        .catch(_error => {
          setBookmarkClass(`${iconClass} bookmark-failure`);
        });
    } else {
      // Add bookmark
      axios.post('/bookmarks.json', {
        authenticity_token: props.authenticity_token,
        bookmark: {
          chapter_id: props.chapter_id,
        }
      })
        .then(response => {
          setBookmarkClass(`${iconClass} bookmarked`);
          setBookmarkId(response.data.id);
        })
        .catch(_error => {
          setBookmarkClass(`${iconClass} bookmark-failure`);
        });
    }
  };

  return (
    <div className="text-center bookmark-button">
      <i className={bookmarkClass} onClick={bookmarkClickHandler}></i>
    </div>
  );
}

export default Bookmark;