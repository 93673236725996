import * as React from "react";

function useFormInput(initValue) {
  const [value, setValue] = React.useState(initValue);

  return [
    value,
    (event) => setValue({
        ...value,
        [event.target.name]: event.target.value,
    }),
    setValue,
  ];
}

export default useFormInput;