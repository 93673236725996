import * as React from 'react';
import range from 'lodash/range';

export interface PageSetting {
  currentPage: number,
  totalPage: number,
}

export interface PaginatorParams {
  pageSetting: PageSetting,
  clickHandler: Function,
}

export const usePageSetting = (props: PageSetting) => React.useState<PageSetting>(props);

const Paginator = (props: PaginatorParams) => {
  if (props.pageSetting.totalPage <= 1) {
    return null;
  }

  return (
    <ul className="pagination">
    {range(1, props.pageSetting.totalPage + 1).map(i => (
        i == props.pageSetting.currentPage ?
        <li key={`page_link_${i}`} className="current">{i}</li> :
        <li key={`page_link_${i}`}><a href="#" onClick={e => props.clickHandler(e)}>{i}</a></li>
    ))}
    </ul>
  );
};

export default Paginator;