import * as React from "react";

interface Tag {
  name: string,
}

const TagControl = (props) => (
  <button type="button" className="tag-control" onClick={props.clickHandler}>{props.name} ×</button>
);

const TagInput = (props) => (
  <input value={props.value} onChange={props.changeHandler} onKeyPress={e => props.keyPressHandler(e)} type="text" />
);

const TagListInput = (props: { flat_tags: string }) => {
  let initTags = props.flat_tags === '' ? [] : props.flat_tags.split(' ').map(tagName => ({ name: tagName }));
  const [tags, setTags] = React.useState<Tag[]>(initTags);
  const [currentTag, setCurrentTag] = React.useState('');

  const tagControls = tags.map((tag: Tag, index: number) => (
    <TagControl key={`tag_control_${index}`} name={tag.name} clickHandler={(e: MouseEvent) => {
      e.preventDefault();

      let newTags = [...tags];
      newTags.splice(index, 1);
      setTags(newTags);
    }} />
  ));

  const tagInputKeyPressHandler = (e) => {
    if (e.key === ' ') {
      let tag: string = e.target.value.trim();
      if (tag !== '') {
        let newTags = [...tags, { name: tag }];
        setTags(newTags);
        setCurrentTag('');
      }
    }
  };

  const tagInputHandler = (e) => {
    if (e.target.value !== ' ') {
      setCurrentTag(e.target.value)
    }
  };

  return (
    <div className="cell">
      <label htmlFor="article[tags]">Tags</label>
      <div className="tags-container">
        {tagControls}
      </div>
      <TagInput value={currentTag} changeHandler={tagInputHandler} keyPressHandler={tagInputKeyPressHandler} />
      <input type="hidden" name="article[tags]" id="article_tags" value={tags.map(t => t.name).join(' ')} />
    </div>
  );
}

export default TagListInput;