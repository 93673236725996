import * as React from 'react';
import axios from 'axios';

type InputCallBack = (event: React.ChangeEvent<HTMLInputElement>) => void;

interface InputOption  {
  id: string,
  name: string,
}

interface SearchInputOption extends InputOption {
  value: string,
  changeHandler: InputCallBack,
}

interface Candidate {
  id: number,
  name: string,
}

interface SearchSuggestionBoxOption {
  candidates: Candidate[],
  clickHandler: Function,
}

export interface SearchOption extends InputOption {
  endPoint: string,
  authenticity_token: string,
  initialValue: string,
};

const SearchInput = (props: SearchInputOption) =>
  <input type="text" id={props.id} name={props.name} value={props.value} onChange={e => props.changeHandler(e)} />;

const SearchSuggestionBox = (props: SearchSuggestionBoxOption) => {
  return (
    <div className="search-suggestion-box">
      <ul className="search-suggestion-box-list no-bullet">
        {props.candidates.map((c) => (
          <li key={`source_${c.id}`} className="search-suggestion-box-list-item">
            <a href="#" className="button" onClick={e => props.clickHandler(e)}>{c.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

const Search = (props: SearchOption) => {
  const [searchValue, setSearchValue] = React.useState(props.initialValue);
  const [isSearchSuggestionVisible, setIsSearchSuggestionVisible] = React.useState(false);
  const [searchCandidates, setSearchCandidates] = React.useState([]);

  const [requestState, setRequestState] = React.useState({
    timeout: null,
    cancelSource: axios.CancelToken.source(),
    isRequesting: false,
  });

  const getSuggestions = (keyword) => {
    if (requestState.isRequesting) {
      requestState.cancelSource.cancel();
    }

    axios.post(props.endPoint, {
      authenticity_token: props.authenticity_token,
      keyword: keyword
    })
      .then(res => {
        setSearchCandidates(res.data);
        if (res.data.length && (res.data.length !== 1 || res.data[0].name !== keyword)) {
          setIsSearchSuggestionVisible(true);
        }

        setRequestState({
          ...requestState,
          isRequesting: false,
        });
      })
      .catch(_err => {
        setRequestState({
          ...requestState,
          isRequesting: false,
        });
        setSearchCandidates([]);
      });
  };

  const inputTypeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    if (requestState.timeout !== null) {
      clearTimeout(requestState.timeout);
    }

    let searchString = e.target.value.trim();
    if (searchString !== '') {
      setRequestState({
        ...requestState,
        timeout: setTimeout(getSuggestions.bind(this, searchString), 1000),
      });
    } else {
        setSearchCandidates([]);
        setIsSearchSuggestionVisible(false);
    }
  };

  const candidateClickHandler = e => {
    e.preventDefault();
    let target: HTMLAnchorElement = e.target as HTMLAnchorElement;
    setSearchValue(target.text);
    setIsSearchSuggestionVisible(false);
  };

  return (
    <div className="grid-x">
      <div className="cell">
        <SearchInput id={props.id} name={props.name} value={searchValue} changeHandler={inputTypeHandler} />
      </div>

      {isSearchSuggestionVisible ? (
        <div className="cell">
          <div className="callout">
            <SearchSuggestionBox candidates={searchCandidates} clickHandler={candidateClickHandler} />
            <button className="close-button" onClick={e => {
              e.preventDefault();
              setIsSearchSuggestionVisible(false);
            }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Search;