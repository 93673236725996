import * as React from "react";
import axios from 'axios';

import useFormInput from './FormInput';
import Alert, { useAlert, EmptyAlert } from './Alert';
import { pluralize, redirectToModel } from './utils';

const RecipeItemComponent = (props) => {
  const { fieldName, fieldValue, index, recipeItems, change } = props;
  const inputId = `${fieldName}`;

  return (
    <td>
      <input
        type="text"
        value={fieldValue}
        name={inputId}
        onChange={e => {
          let newRecipeItems = [...recipeItems];
          newRecipeItems[index] = {
            ...newRecipeItems[index],
            [e.target.name]: e.target.value
          };
          change(newRecipeItems);
        }}
        id={inputId}
      />
    </td>
  );
}

const RecipeItemInput = (props) => {
  const { item, index, recipeItems, change } = props;

  return (
    <tr>
      {Object.keys(item).map((itemName) =>
        <RecipeItemComponent
          key={itemName + '_' + index}
          fieldName={itemName}
          fieldValue={item[itemName]}
          recipeItems={recipeItems}
          change={change}
          index={index} />)}
      <td>
        <button type="button" className="button" onClick={e => {
          let removedRecipeItems = [...recipeItems];
          removedRecipeItems.splice(index, 1);
          change(removedRecipeItems);
        }}>-</button>
      </td>
    </tr>
  );
};

function RecipeItem() {
  this.ingredient_name = '';
  this.quantity = 1;
  this.unit_name = '';
};

const RecipeForm = (props) => {
  const [updateFlag, setUpdateFlag] = React.useState(false);
  // Create hooks
  const [formValue, onFormValueChange, setFormValue] = useFormInput({
    name: '',
    steps: '',
  });
  const [recipeItems, setRecipeItems] = React.useState(Array(5).fill(new RecipeItem()));
  const [categoryId, setCategoryId] = React.useState(0);
  const [categoryOptions, setCategoryOptions] = React.useState([{
    id: 0,
    name: 'Uncategoried',
  }]);
  const [errors, setErrors, setErrorTitleOnly, setErrorMessageOnly, clearErrors] = useAlert(EmptyAlert);

  // Create effect hook to get form for editing
  React.useEffect(() => {
    if (props.recipe_id !== null) {
      setUpdateFlag(true);
      axios(`/recipes/${props.recipe_id}.json`)
        .then(response => {
          let existingRecipe = response.data;
          setFormValue({
            ...formValue,
            name: existingRecipe.name,
            steps: existingRecipe.steps,
          });

          setRecipeItems(existingRecipe.recipe_items);
          if (existingRecipe.category_id !== null) {
            setCategoryId(existingRecipe.category_id);
          }
        }).catch(err =>
          setErrors({
            title: 'Failed to get the recipe to edit',
            messages: [err.statusText],
          }));
    }
  }, []);

  // Create effect hook to get categories for selecting
  React.useEffect(() => {
    axios('/categories.json')
      .then(response => setCategoryOptions([...categoryOptions].concat(response.data)))
      .catch(error => setErrorTitleOnly(error));
  }, []);

  const recipeItemInputs = recipeItems.map((item, index) =>
    <RecipeItemInput
      key={'recipe_item_' + index}
      item={item}
      index={index}
      recipeItems={recipeItems}
      change={setRecipeItems} />
  );

  const categoriesSelect = (
    <select id="category-select" value={categoryId} onChange={e => setCategoryId(parseInt(e.target.value))}>
      {categoryOptions.map((category) => (
        <option key={`category_${category.id}`} value={category.id}>{category.name}</option>
      ))}
    </select>
  );

  const newRecipe = (recipe) => axios.post('/recipes.json', recipe);
  const updateRecipe = (recipe, id) => axios.patch(`/recipes/${id}.json`, recipe);

  // Submit form callback
  const submitRecipe = (e) => {
    e.preventDefault();

    clearErrors();

    // Validate inputs
    let recipe = { ...formValue };
    let recipeItemsToSubmit = [...recipeItems].filter(item => item.ingredient_name !== '');
    if (recipeItemsToSubmit.some(item => item.quantity <= 0 || item.unit_name === '')) {
      setErrors({
        title: 'Invalid recipe items',
        messages: ['Some recipe items contains invalid quantity or unit.'],
      });

      return;
    }

    // Create JSON for submitting form
    recipe.recipe_items_attributes = [...recipeItemsToSubmit];
    if (categoryId !== 0) {
      recipe.category_id = categoryId;
    }

    let formToPost = {
      authenticity_token: props.authenticity_token,
      'recipe': recipe,
    }

    // Submit form
    let submitResult = updateFlag ? updateRecipe(formToPost, props.recipe_id) : newRecipe(formToPost);
    submitResult
      .then(response => {
        // Redirect to show page if submitted
        redirectToModel('recipes', response.data.id);
      })
      .catch(error => {
        if (error.response.status === 422) {
          let errorMessagesJson = error.response.data;
          let errorMessages = Object.keys(errorMessagesJson).map(errorKey => `${errorKey} ${errorMessagesJson[errorKey]}`);
          setErrors({
            title: 'Failed to submit the recipe due to the following ' + pluralize('error', errorMessages.length) + '.',
            messages: errorMessages,
          });
        } else {
          setErrors({
            title: 'Failed to submit the recipe due to the following error.',
            messages: [error.response.statusText],
          });
        }
      });
  }

  return (
    <form id="recipe" onSubmit={submitRecipe} autoComplete="off">
      <input type='hidden' name='authenticity_token' value={props.authenticity_token} />

      <Alert title={errors.title} messages={errors.messages} />

      <label htmlFor="name"><strong>Name</strong></label>
      <input type="text" id="recipe_name" name="name" value={formValue.name} onChange={onFormValueChange} />

      <label htmlFor="category-select">Category</label>
      {categoriesSelect}

      <label htmlFor="steps"><strong>Steps</strong></label>
      <textarea id="recipe_steps" name="steps" cols={30} rows={10} value={formValue.steps} onChange={onFormValueChange}></textarea>

      <div className="cell">
        <a href="#" onClick={e => {
          e.preventDefault();
          let appendedRecipeItems = [...recipeItems, new RecipeItem()];
          setRecipeItems(appendedRecipeItems);
        }}>Ingredients <i className="fa fa-plus"></i></a>

      </div>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Quantity</th>
            <th>Unit</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {recipeItemInputs}
        </tbody>
      </table>

      <button type="submit" className="button">{updateFlag ? 'Update' : 'Create'} Recipe</button>

    </form>
  );
}

export default RecipeForm