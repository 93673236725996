import * as Turbolinks from 'turbolinks';

export function pluralize(word: string, number: number) {
  if (number == 1) {
    return word;
  } else {
    return word + 's';
  }
}

export function redirectToModel(modelName: string, index: number) {
  Turbolinks.visit(`/${modelName}/${index}`);
}