import * as React from "react";
import axios from 'axios';

interface SelectOption {
  id: number,
  name: string,
}

type SetSelectType = React.Dispatch<React.SetStateAction<number>>;
type SelectChangeHandler = (e: React.SyntheticEvent) => void;

interface SelectInputParams {
  value: number,
  setValue: SetSelectType,
  label: string,
  htmlId: string,
  name: string,
  options: SelectOption[],
  handleOnChange?: SelectChangeHandler,
}

interface SeriesCollectionInputParam {
  series_id: number,
  collection_id: number
}

const useSelectInput = (initValue: number): [number, SetSelectType] => React.useState<number>(initValue);

const SelectInput = (props: SelectInputParams) => {
  const defaultOnChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLSelectElement;
    props.setValue(parseInt(target.value));
  };

  return (
    <>
      <label htmlFor={props.htmlId}>{props.label}</label>
      <select name={props.name} id={props.htmlId} value={props.value} onChange={
        e => props.handleOnChange !== undefined ?
          props.handleOnChange(e) :
          defaultOnChange(e)}>
        {props.options.map(option => (
          <option key={`option_id_${option.id}`} value={option.id}>
            {option.name}
          </option>))}
      </select>
    </>
  );
}

const SeriesCollectionInput = (props: SeriesCollectionInputParam) => {
  const [seriesValue, setSeriesValue] = useSelectInput(props.series_id);
  const [collectionValue, setCollectionValue] = useSelectInput(props.collection_id);
  const [seriesOptions, setSeriesOptions] = React.useState<SelectOption[]>([]);
  const [collectionOptions, setCollectionOptions] = React.useState<SelectOption[]>([]);

  const getCollections = (seriesId: number, isOnChange: boolean) => axios(`/series/${seriesId}/collections.json`)
    .then(response => {
      let options: SelectOption[] = response.data;
      setCollectionOptions(options);
      if (options.length && isOnChange) {
        setCollectionValue(options[0].id);
      }
    });

  // Get series list
  React.useEffect(() => {
    axios('/series.json')
      .then(response => {
        let options: SelectOption[] = response.data;
        setSeriesOptions(options);
        if (options.length) {
          getCollections(seriesValue, false);
        }
      });
  }, []);

  const handleSeriesOnChange: SelectChangeHandler = (e) => {
    const target = e.target as HTMLSelectElement;
    const seriesId: number = parseInt(target.value);
    setSeriesValue(seriesId);
    getCollections(seriesId, true);
  }

  return (
    <>
      <SelectInput label="Series" name="article[series_id]" htmlId="article_series_id" value={seriesValue} setValue={setSeriesValue} options={seriesOptions} handleOnChange={handleSeriesOnChange} />
      <SelectInput label="Collection" name="article[collection_id]" htmlId="article_collection_id" value={collectionValue} setValue={setCollectionValue} options={collectionOptions} />
    </>
  );
};

export default SeriesCollectionInput;