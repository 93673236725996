import * as React from "react";

export const EmptyAlert = {
  title: '',
  messages: [],
};

export const useAlert = (props) => {
  const [alert, setAlert] = React.useState(props);

  return [
    alert,
    setAlert,
    (title: string) => setAlert({
      'title': title,
      messages: [],
    }),
    (messages: string[]) => setAlert({
      title: '',
      'messages': messages,
    }),
    () => setAlert(EmptyAlert),
  ];
};

const Alert = (props) => {
  if (props.title !== '' || props.messages !== []) {
    return (
      <div id="error_explanation">
        {props.title !== '' &&
          <h2>
            {props.title}
          </h2>
        }
        {props.messages.length !== 0 &&
          <ul>
            {props.messages.map((message, index) => <li key={`error_${index}`}>{message}</li>)}
          </ul>
        }
      </div>
    )
  } else {
    return null;
  }
};

export default Alert;