import * as React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import axios from 'axios';

import Alert, { EmptyAlert, useAlert } from './Alert';
import DateInput, { useDateInput, formatDate } from './DateTimeInput';
import { pluralize } from './utils';
import Paginator, { usePageSetting } from './Paginator';

const NewsQuery = (props) => {
  const [startDate, setStartDate] = useDateInput();
  const [endDate, setEndDate] = useDateInput();
  const [newsType, setNewsType] = React.useState('');
  const [source, setSource] = React.useState('');
  const [keyword, setKeyWord] = React.useState('');
  const [alert, setAlert, setAlertTitleOnly, setAlertMessagesOnly, clearAlert] = useAlert(EmptyAlert);
  const [news, setNews] = React.useState([]);
  const [queryButtonClass, setQueryButtonClass] = React.useState('button');
  const [pageSetting, setPageSetting] = usePageSetting({
    currentPage: 1,
    totalPage: 1,
  });

  const isQuerying = (): boolean => queryButtonClass.indexOf('disabled') !== -1;

  const queryParams = (page?: number) => ({
    authenticity_token: props.authenticity_token,
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
    type: newsType,
    source: source,
    keyword: keyword,
    page: page || pageSetting.currentPage
  });

  const runQuery = (event) => {
    event.preventDefault();
    clearAlert();
    setNews([]);

    let errors: string[] = [];

    if (new Date(formatDate(startDate)) > new Date(formatDate(endDate))) {
      errors.push('Start date should be less than end date.');
    }

    if (errors.length) {
      setAlert({
        title: 'Query cannot run because of the following ' + pluralize('error', errors.length),
        messages: errors,
      });

      return;
    }

    setQueryButtonClass('button disabled');
    setTimeout(() => setQueryButtonClass('button'), 3000);

    axios.post('/news_query/total_page.json', queryParams())
      .then(response => setPageSetting({
        ...pageSetting,
        totalPage: response.data.total_page,
      }));

    axios.post('/news_query/query.json', queryParams())
      .then(response => {
        setQueryButtonClass('button');
        setNews(response.data);
      })
      .catch(error => setAlertTitleOnly(error));
  };

  const turnPage = (e) => {
    e.preventDefault();
    let page: number = parseInt(e.target.text);
    setPageSetting({
      ...pageSetting,
      currentPage: page,
    });
    axios.post('/news_query/query.json', queryParams(page))
      .then(response => setNews(response.data))
      .catch(error => setAlertTitleOnly(error));
  };

  const queryResult = news.map(n => (
    <tr key={`news_${n.id}`}>
      <td><span className="long-date">{format(parseISO(n.published_time), 'yyyy-MM-dd')}</span><span className="short-date">{format(parseISO(n.published_time), 'MM-dd')}</span></td>
      <td><a href={`/news/${n.id}`}>{n.content}</a></td>
      <td><a href={`/source/${n.source_id}`}>{n.source}</a></td>
      <td><a href={`/news/${n.id}`} className="delete-button" rel="nofollow" date-remote="true" data-method="delete">x</a></td>
    </tr>
  ));

  return (
    <div className="grid-x">
      <div className="cell"><h1>News Query</h1></div>
      <div className="cell">
        <form action="/news_query/query" method="POST">
          <Alert title={alert.title} messages={alert.messages} />
          <label><i className="fa fa-calendar"></i> Start date</label>
          <DateInput dateValue={startDate} setDateValue={setStartDate} />

          <label><i className="fa fa-calendar"></i> End date</label>
          <DateInput dateValue={endDate} setDateValue={setEndDate} />

          <label htmlFor="news-type"><i className="fa fa-list"></i> Type</label>
          <input type="text" name="news-type" id="news-type" value={newsType} onChange={e => setNewsType(e.target.value)} />

          <label htmlFor="source"><i className="fa fa-link"></i> Source</label>
          <input type="text" name="source" id="source" value={source} onChange={e => setSource(e.target.value)} />

          <label htmlFor="keyword">Keyword</label>
          <input type="text" name="keyword" id="keyword" value={keyword} onChange={e => setKeyWord(e.target.value)} />

          <button type="submit" className={queryButtonClass} onClick={runQuery}><i className="fa fa-search"></i> Run Query</button>
          { isQuerying() ? <span className="spinner-container"><i className="fa fa-spinner query-spinner"></i></span> : null }
        </form>
      </div>
      <div className="cell">
        <table>
          <thead>
            <tr>
              <th>Published time</th>
              <th>Content</th>
              <th>Source</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {queryResult}
          </tbody>
        </table>
      </div>
      <div className="cell text-center">
        <Paginator pageSetting={pageSetting} clickHandler={turnPage}></Paginator>
      </div>
    </div>
  );
}

export default NewsQuery
