function innerFetch(url: string, action: string, data: any) {
  return fetch(url, {
    method: action,
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  });
}

export function getJson(url: string) {
  return fetch(url);
}

export function postJson(url: string, data: any) {
  return innerFetch(url, 'POST', data);
}

export function patchJson(url: string, data: any) {
  return innerFetch(url, 'PATCH', data);
}

export function deleteJson(url: string) {
  return fetch(url, {
    method: 'DELETE',
  });
}