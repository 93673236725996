import * as React from 'react';
import isValid from 'date-fns/isValid';

export interface DateInputDate {
  year: number,
  month: number,
  day: number,
}

export const useDateInput = () => {
  let now = new Date();

  return React.useState({
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  });
};

export const formatDate = (date: DateInputDate) => `${date.year}-${date.month}-${date.day}`;

export const isDateInputValid = (date: DateInputDate) => {
  return isValid(new Date(formatDate(date)));
}

const DateInput = (props: { dateValue: DateInputDate, setDateValue: React.Dispatch<React.SetStateAction<DateInputDate>> }) => {
  const onDateChange = (event) => props.setDateValue({
    ...props.dateValue,
    [event.target.name]: event.target.value,
  });

  return (
    <div className="grid-x">
      <input type="number" name="year" min="0" max="2300" value={props.dateValue.year} onChange={onDateChange} className="cell small-4" />
      <input type="number" name="month" min="1" max="12" value={props.dateValue.month} onChange={onDateChange} className="cell small-4" />
      <input type="number" name="day" min="1" max="31" value={props.dateValue.day} onChange={onDateChange} className="cell small-4" />
    </div>
  );
}

export default DateInput;